/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto';
  color: #ffffff;
}

h1 {
  color: #000;
}

h3 {
  color: #000;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: #EEF1FF;
}

.App-header {
  width: 100vw;
  text-align: center;
  background-color: #B1B2FF; /* Teal color */
}

.embedded-browser + .App-header {
  padding: 5px 0; /* Even smaller padding when iframe is active */
}

.links-container {
  width: 100%;
  max-width: 800px;
  margin-top: 40px;
  padding: 10px;
  border-radius: 8px;
  background-color: #D2DAFF;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.links-container ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.links-container li {
  padding: 6px;
  width: 90%%;
  border-radius: 4px;
  background-color: #fff; /* Teal color */
  transition: background-color 0.3s ease;
}

.links-container li:hover {
  background-color: #EEF1FF; /* Darker Teal */
}

.links-container a,
.links-container button {
  text-decoration: none;
  font-size: 14pt;
  color: #000; /* Teal color */
  border: none;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  text-align: left;
}

.links-container a:hover,
.links-container button:hover {
transform: scale(100pt);
}

.category-section {
  margin-top: 20px;
}

.category-section h3 {
  border-bottom: 2px solid #D2DAFF; /* Teal color */
  display: inline-block;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .links-container {
    margin-top: 20px;
    padding: 10px;
  }

  .links-container ul {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.embedded-browser {
  width: 100%;
  height: calc(100vh - 60px); /* Adjust based on your header's height */
  border: none;
}

.close-button {
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  width: 100vw;
  height: 40px;
  background-color: #B1B2FF; /* Teal color */
  color: #000;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #D2DAFF; /* Darker Teal */
}